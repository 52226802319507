<template>
    <div :class="{dark:$store.state.appConfig.layout.skin === 'dark'}">
        <b-card v-if="!loading">
            <b-card-body>
                <b-form @submit.prevent="saveForm">
                    <b-row>
                        <b-col cols="12">
                            <b-form-group
                                label="Kategori"
                                label-for="categoryId"
                                label-cols-md="4"
                                label-cols-lg="3"
                                label-cols-xl="2"
                            >
                                <b-form-select
                                    id="categoryId"
                                    placeholder="Kategori"
                                    v-model="item.categoryId"
                                    :options="categoryListComputed"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group
                                label="Başlık"
                                label-for="title"
                                label-cols-md="4"
                                label-cols-lg="3"
                                label-cols-xl="2"
                            >
                                <b-form-input
                                    id="title"
                                    placeholder="Başlık"
                                    v-model="item.title"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group
                                label="İçerik"
                                label-for="title"
                                label-cols-md="4"
                                label-cols-lg="3"
                                label-cols-xl="2"
                            >
                                <quill-editor
                                    v-model="item.text"
                                    :options="snowOption"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group
                                label="Kapak Görseli"
                                label-for="fileName"
                                label-cols-md="4"
                                label-cols-lg="3"
                                label-cols-xl="2"
                            >
                                <label class="cursor-pointer">
                                    <b-img :src="$basePath+item.fileName" v-if="item.fileName || item.file" rounded style="max-width:155px" :ref="'imgPreview'" />
                                    <feather-icon icon="UploadIcon" v-else size="45" />
                                    <input type="file" class="hiddenFile" id="fileName" @input="setInput" :ref="'fileInputInsert'" />
                                </label>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" class="d-flex justify-content-end">
                            <b-button variant="outline-primary px-1" type="submit">
                                Kaydet
                                <feather-icon icon="SaveIcon" />
                            </b-button>
                        </b-col>
                    </b-row>





                </b-form>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>

import {
    BImg, BCard, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BFormTextarea, BFormSelect, BSpinner, BCardBody
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import 'quill/dist/quill.snow.css'
import { quillEditor } from 'vue-quill-editor'
import formData from "form-data";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
    name: "sForm",
    props: {
        item : Object
    },
    components: {
        BCard,
        BForm,
        BFormGroup,
        BFormInput,
        BButton,
        BFormTextarea,
        BFormSelect,
        BSpinner,
        BImg,
        BRow,
        BCol,
        quillEditor,
        BCardBody
    },
    directives: {
        Ripple
    },
    data(){
        return{
            snowOption: {
                theme: 'snow',
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                        ['blockquote', 'code-block'],

                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent

                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                        [{ 'align': [] }],

                        ['clean']                                         // remove formatting button
                    ]
                }
            },
            categories: false,
            loading: true,
        }
    },
    created() {
        let t = this
        t.$http.get('/admin/blog/category/list')
            .then(res => {
                if(res.data.status){
                    t.categories = res.data.data
                }else if(res.data.message){
                    t.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Hata`,
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                            text: res.data.message,
                        },
                    })
                }
            }).catch(error=>{
            console.log(error)
        }).then(()=>{
            t.loading = false
        })
        store.state.app.editableSlotId = 0
    },
    computed: {
        categoryListComputed(){
            let items = []
            for(let i = 0; i<this.categories.length; i++){
                items.push({value:this.categories[i].id, text: this.categories[i].title})
            }
            return items
        }
    },
    methods:{
        saveForm(){
            let t = this
            let data = new formData()

            data.append('title', t.item.title)
            data.append('text', t.item.text)
            data.append('categoryId', t.item.categoryId)
            if(t.item.file){
                data.append('fileName', t.item.file)
            }




            store.commit('app/SET_LOADING', true)
            t.$http.post(
                t.item.id?'admin/blog/content/edit/'+t.item.id:'admin/blog/content/add/'+t.item.categoryId,
                data
            ).then((result)=>{
                if(result.data.status){

                    if(result.data.message){
                        t.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: `Başarılı!`,
                                icon: 'CheckIcon',
                                variant: 'success',
                                text: result.data.message,
                            },
                        })
                    }

                    if(!t.item.id){
                        t.$router.push({name:'blog_content_edit', params:{id:result.data.data.id}})
                    }
                }else{
                    t.$swal({
                        title: 'Hata!',
                        text: result.data.message?result.data.message:'İçerik eklenemedi. Lütfen daha sonra tekrar deneyin.',
                        icon: 'error',
                        confirmButtonText: 'Tamam'
                    })
                }
            }).catch((error)=>{
                console.log(error)
                t.$swal({
                    title: 'Hata!',
                    text: 'İçerik eklenemedi. Lütfen daha sonra tekrar deneyin.',
                    icon: 'error',
                    confirmButtonText: 'Tamam'
                })
            }).then((e)=>{
                store.commit('app/SET_LOADING', false)
            })




        },
        setInput(){
            let file = this.$refs['fileInputInsert'].files[0]

            if(!file){return}

            this.item.file = file
            this.$forceUpdate()

            const reader = new FileReader
            reader.onload = e => {
                this.$refs['imgPreview'].src = e.target.result
            }
            reader.readAsDataURL(file)
        },
    }
}
</script>

<style lang="scss">
    .dark{
        .ql-stroke{
            stroke: #fff !important;
        }
        .ql-fill{
            fill: #fff !important;
        }
        .ql-picker-label{
            color: #fff !important;
        }
    }
    .hiddenFile{
        position: fixed;
        top: -9999px;
        left: -9999px;
    }

</style>
