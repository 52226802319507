<template>
    <div>
        <b-alert :variant="loading?'primary':'danger'" :show="!item">
            <div class="alert-body py-3 px-2">
                <span><strong v-if="loading">Veriler yükleniyor. <b-spinner label="Loading..." /></strong><strong v-else>Veri bulunamadı.</strong></span>
            </div>
        </b-alert>
        <s-form v-if="!loading && item" :item="item" />
    </div>
</template>

<script>
import {BAlert, BSpinner} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import sForm from './sForm'

export default {
    name: "edit",
    components: {
        BAlert,
        BSpinner,
        sForm
    },
    data(){
        return {
            item: false,
            loading: true
        }
    },
    created(){
        let t = this
        t.$http.get('/admin/blog/content/item/'+t.$route.params.id)
            .then(res => {
                if(res.data.status){
                    t.item = res.data.data
                }else if(res.data.message){
                    t.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Hata`,
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                            text: res.data.message,
                        },
                    })
                }
            }).catch(error=>{
            console.log(error)
        }).then(()=>{
            t.loading = false
        })
    }
}
</script>

<style scoped lang="scss">

</style>
